/*
 * @Description:
 * @Autor: 郝志杰
 * @Date: 2021-10-29 10:17:56
 * @LastEditors: 郝志杰
 * @LastEditTime: 2024-06-11 09:46:28
 */

import { postRequest } from './http';
let SERVICE_NAME = 'metric-main';
// 获取用户信息
export const getUse = (params) => {
    return postRequest(SERVICE_NAME + '/fIngerTip/testRaperLogs/getWeiXinUser', params);
};

// 获取试卷
export const getPaper = (params) => {
    return postRequest(SERVICE_NAME + '/fIngerTip/testQuestions/automaticallyGeneratedQuestion', params);
};
// 提交答案
export const saveAnswer = (params) => {
    return postRequest(SERVICE_NAME + '/fIngerTip/testRaperLogs/save', params);
};

